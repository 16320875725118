<template>
  <v-navigation-drawer
    :value="isDocumentMenuVisible"
    persistent
    disable-resize-watcher
    fixed
    app
    clipped
    width="350"
    right
    @input="setDocumentMenu"
  >
    <v-toolbar
      dark
      dense
      flat
      color="primary"
    >
      <v-spacer />
      <v-toolbar-title>
        Documents
      </v-toolbar-title>
      <v-spacer />
    </v-toolbar>
    <v-list
      two-line
    >
      <template v-for="document in batch.partNumber.documents">
        <v-list-item :key="document._id" @click="openDocument(document)">
          <v-list-item-content>
            <v-list-item-title class="text-body-1">
              {{ document.name }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-divider :key="document._id + 'divider'" class="mx-4" />
      </template>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'DocumentList',
  components: {},
  computed: {
    ...mapGetters('ui', {
      isDocumentMenuVisible: 'isDocumentMenuVisible',
    }),
    ...mapGetters('batch', {
      batch: 'batch',
    }),
  },
  methods: {
    ...mapActions('ui', {
      setDocumentMenu: 'setDocumentMenu',
    }),
    openDocument (document) {
      window.open(
        this.$config.APP_URL + document.file,
        '_blank',
      )
    },
  },
}
</script>
