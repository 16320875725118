<template>
  <v-navigation-drawer
    :value="isMenuVisible"
    persistent
    enable-resize-watcher
    fixed
    app
    clipped
    width="350"
    @input="setMenu"
  >
    <v-toolbar
      dark
      dense
      flat
      color="primary"
    >
      <v-spacer />
      <v-toolbar-title>
        Opérations successives
      </v-toolbar-title>
      <v-spacer />
    </v-toolbar>
    <v-list
      two-line
    >
      <template v-for="operation in batch.orderOperations">
        <operation-list-item :key="operation._id" :operation-id="operation._id" />
        <v-divider :key="operation._id + 'divider'" class="mx-4" />
      </template>
    </v-list>
    <v-toolbar
      v-if="batch.unorderOperations.length"
      dark
      dense
      flat
      color="primary"
    >
      <v-spacer />
      <v-toolbar-title>
        Opérations parrallèles
      </v-toolbar-title>
      <v-spacer />
    </v-toolbar>
    <v-list
      v-if="batch.unorderOperations.length"
    >
      <template v-for="operation in batch.unorderOperations">
        <operation-list-item :key="operation._id" :operation-id="operation._id" />
        <v-divider :key="operation._id + 'divider'" class="mx-4" />
      </template>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import OperationListItem from './OperationListItem'

export default {
  name: 'OperationList',
  components: {
    OperationListItem,
  },
  computed: {
    ...mapGetters('ui', {
      isMenuVisible: 'isMenuVisible',
    }),
    ...mapGetters('batch', {
      batch: 'batch',
    }),
  },
  methods: {
    ...mapActions('ui', {
      setMenu: 'setMenu',
    }),
  },
}
</script>
