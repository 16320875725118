<template>
  <v-app-bar
    color="lightbg"
    app
    clipped-right
    style="z-index: 6"
    clipped-left
  >
    <v-btn
      light
      fab
      small
      @click.stop="toggleMenu"
    >
      <v-icon color="primary">
        menu
      </v-icon>
    </v-btn>
    <v-btn
      light
      class="ml-2"
      fab
      small
      @click.stop="getBatch(batch._id)"
    >
      <v-icon color="primary">
        refresh
      </v-icon>
    </v-btn>
    <v-switch
      v-model="darkMode"
      class="ml-4"
      hide-details
      inset
      color="accent"
    />

    <v-spacer />

    <v-toolbar-title>
      <strong>Réf:</strong> {{ batch.partNumber.name }}
      <strong>OF:</strong> {{ batch.number }}
      <strong>Qté:</strong> {{ batch.quantity }}
    </v-toolbar-title>

    <v-spacer />

    <current-user-button-menu />

    <v-btn
      light
      fab
      small
      @click.stop="toggleDocumentMenu"
    >
      <v-icon color="primary">
        attach_file
      </v-icon>
    </v-btn>
  </v-app-bar>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

import CurrentUserButtonMenu from 'src/components/layout/CurrentUserButtonMenu'

export default {
  name: 'BatchAppBar',
  components: {
    CurrentUserButtonMenu,
  },
  props: {
    batch: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters('ui', {
      isDarkMode: 'isDarkMode',
    }),
    darkMode: {
      get () {
        return this.isDarkMode
      },
      set (val) {
        this.toggleDark(val)
      },
    },
  },
  methods: {
    ...mapActions('ui', {
      toggleMenu: 'toggleMenu',
      toggleDocumentMenu: 'toggleDocumentMenu',
      toggleDark: 'toggleDark',
    }),
    ...mapActions('batch', {
      getBatch: 'getBatch',
    }),
  },
}
</script>
