<template>
  <v-list-item
    :disabled="disableItem"
    :to="'/batch/'+ batch._id + '/operation/' + operationId"
  >
    <v-list-item-content>
      <v-list-item-title class="text-body-1">
        {{ batchOperation.operation.operationType.name }}
      </v-list-item-title>
      <v-list-item-subtitle>
        Rebut: {{ batchOperation.stats.rejectedList.length }}
        Quarantaine: {{ suspendedPartCount }}
      </v-list-item-subtitle>
    </v-list-item-content>
    <v-list-item-action v-if="batchOperation.status < 2">
      <v-list-item-action-text class="text-subtitle-1">
        {{
          batchOperation.operation.operationType.control
            ? '-'
            : batchOperation.todoList.length }}
      </v-list-item-action-text>
    </v-list-item-action>
    <v-list-item-action v-else>
      <v-icon color="success">
        check
      </v-icon>
    </v-list-item-action>
  </v-list-item>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'OperationListItem',
  props: {
    operationId: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapGetters('batch', {
      batch: 'batch',
      batchOperationGetter: 'batchOperation',
    }),
    ...mapGetters('me', {
      role: 'role',
    }),
    batchOperation () {
      return this.batchOperationGetter(this.operationId)
    },
    suspendedPartCount () {
      if (this.batchOperation.operation.operationType.control) {
        return this.batchOperation.stats.controlSuspendedList.length
      }
      return this.batchOperation.stats.suspendedList.length
    },
    disableItem () {
      if (this.batchOperation.status === 0) return true
      if (this.batchOperation.status === 2 && this.role.level === 1) return true
      return false
    },
  },
}
</script>
