<template>
  <v-app :style="`background-color: ${$vuetify.theme.dark ? '#1e1e1e' : '#F7F9FB'};`">
    <batch-app-bar v-if="batch" :batch="batch" />
    <operation-list v-if="batch" :batch="batch" />
    <document-list v-if="batch" :batch="batch" />

    <v-main>
      <v-overlay z-index="99" :value="batchIsLoading">
        <v-card color="primary" class="pa-1">
          <v-card-text class="pa-4">
            Chargement de la gamme
            <v-progress-linear
              class="my-2"
              indeterminate
              color="white"
            />
          </v-card-text>
        </v-card>
      </v-overlay>
      <v-slide-x-transition mode="out-in">
        <router-view v-if="batch" :key="$route.params.operationId" />
      </v-slide-x-transition>
    </v-main>
    <ag-notify />
    <update-me-container />
    <switch-user-container />
    <check-user-container />
  </v-app>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import AgNotify from 'src/components/common/AgNotify'
import BatchAppBar from '../components/BatchAppBar'
import OperationList from '../components/OperationList'
import DocumentList from '../components/DocumentList'
import UpdateMeContainer from '../../general/containers/UpdateMeContainer'
import SwitchUserContainer from '../../general/containers/SwitchUserContainer'
import CheckUserContainer from '../../general/containers/CheckUserContainer'

export default {
  name: 'OpenBatchContainer',
  components: {
    BatchAppBar,
    AgNotify,
    OperationList,
    UpdateMeContainer,
    SwitchUserContainer,
    CheckUserContainer,
    DocumentList,
  },
  data: () => ({}),
  computed: {
    ...mapGetters('batch', {
      batch: 'batch',
      batchIsLoading: 'loading',
    }),
    batchId () {
      return this.$route.params.batchId
    },
  },
  watch: {
    batchId: {
      immediate: true,
      async handler () {
        this.getBatch(this.batchId)
      },
    },
  },
  methods: {
    ...mapActions('ui', {
      showErrorNotification: 'showErrorNotification',
    }),
    ...mapActions('batch', {
      getBatch: 'getBatch',
    }),
  },
}
</script>
